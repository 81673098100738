import React from "react";
import Header from "./Header";
import Main from "./Main";
import Cooperation from "./Cooperation";
import CTA from "./CTA";

function Home (){
    return (
        <>
            <Header />
            <Cooperation />
            <CTA />
        </>
    )
}

export default Home;