import { createGlobalStyle } from "styled-components";
import { MAIN_PRIMARY_COLOR, MAIN_TEXT_COLOR } from "../helpers/colors";
import '../fonts/line-icons.css';

const GlobalStyles = createGlobalStyle`
    *{
        font-family: "Heebo", sans-serif;
        font-weight: 400;
        margin: 0 auto;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    *::-webkit-scrollbar{
		width:8px;
		background-color: #7f8bcb;
        border-radius: 16px;
	}
	*::-webkit-scrollbar:horizontal{
		height:12px;
        border-radius: 16px;
	}
	*::-webkit-scrollbar-track{
		border:1px #203bd1 solid;
		border-radius: 16px;
		-webkit-box-shadow:0 0 6px #203bd1 inset;
	}
	*::-webkit-scrollbar-thumb{
		background-color: #203bd1;
		border:1px solid #203bd1;
		border-radius:16px;
	}
	*::-webkit-scrollbar-thumb:hover{
		background-color:#0629e7;
		border:1px solid #0629e7;
	}
	*::-webkit-scrollbar-thumb:active{
		background-color:#0629e7;
		border:1px solid #0629e7;
	}
    body {
        position:relative;
        min-height: 100vh;
        font-size: 1rem;
        margin: 0;
        // color: #ffffff;
        // background: #161822;
        color: ${MAIN_TEXT_COLOR};
        background: #f8f9fa;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
    a{
        // text-decoration: none;
        color: ${MAIN_TEXT_COLOR};;
        &:hover{
            color: #0629e7;
        }
    }
    .MuiSlider-track {
        background-color: ${MAIN_PRIMARY_COLOR} !important;
        outline: none !important;
        border: none !important;
    }
    .MuiSlider-thumb {
        background-color: ${MAIN_PRIMARY_COLOR} !important;
    }
    .MuiAutocomplete-tag {
        margin: 0 5px !important;
        height: 25px !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    };

    .flag {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin: 0 0 0 10px;
        filter: grayscale(1);
      }
      .flag:hover {
        filter: grayscale(0.5);
      }
      .selected {
        filter: grayscale(0);
      }
      .selected:hover {
        filter: grayscale(0);
      }

      .md-description {
          strong { font-weight: bold!important;}
      }
`;

export default GlobalStyles;