import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { MAIN_WHITE_TEXT_COLOR } from '../helpers/colors';


//ModalStyle
export const CTA_MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 360,
  backgroundColor: MAIN_WHITE_TEXT_COLOR,
  borderRadius: "20px",
  boxShadow: "24",
  p: 4,
  color: "black",
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width:700px)']:{
    width: 400,
    height: 390,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width:600px)']:{
    width: 300,
    height: 650,
    p: 3,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width:520px)']:{
    width: 220,
    height: 690,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width:370px)']:{
    width: 220,
    height: 680,
  },
  ['@media (max-width:320px)']:{
    width: 220,
    height: 680,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:640px)']:{
    top: "50%",
    left: "50%",
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:540px)']:{
    top: "50%",
    left: "50%",
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:440px)']:{
    top: "50%",
    left: "50%",
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:390px)']:{
    top: "80%",
    left: "50%",
  },
  ['@media (max-height:350px)']:{
    top: "120%",
    left: "50%",
  },
  ['@media (max-height:300px)']:{
    top: "150%",
    left: "50%",
  },
  ['@media (max-height:250px)']:{
    top: "225%",
    left: "50%",
  },
};

function CTAModal(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflowY: "scroll", overflowX: "hidden" }}
    >
      <Box sx={CTA_MODAL_STYLE}>
        {props.children}
      </Box>
    </Modal>
  )
}

export default CTAModal;