import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';
import useMedia from "use-media";
import { MAIN_PRIMARY_COLOR, MAIN_TEXT_COLOR } from "../../helpers/colors";

const StyledLink = styled(Link)`
  text-decoration: none;
  text-align: left;
  display: block;
  color: ${MAIN_TEXT_COLOR};
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    color: ${MAIN_PRIMARY_COLOR};
  }
  @media(max-width: 500px){
    font-size: .9rem;
  }
  @media(max-width: 400px){
    font-size: .7rem;
  }
`;

const StyledNavbar = styled.nav`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 10px;
  justify-content: space-between;
  max-width: 1200px;
  background: #f8f9fa;
  @media(max-width: 1000px){
    padding: 20px 10px;
    max-width: 900px;
    position: sticky;
    top: 0px;
    z-index: 4;
  }
  @media(max-width: 700px){
    padding: 20px 10px;
  }
  @media(max-width: 600px){
    padding: 10px;
    margin: auto;
  }
  @media(max-width: 500px){
    padding: 10px;
  }
  @media(max-width: 400px){
    padding: 5px;
  }
`;

export const StyledLogo = styled.p`
  margin: 0;
  font-weight: 600;
  color: #004265;
  font-size: ${props => props.fontSize ? props.fontSize : "2rem"};
  font-family: 'Audiowide';
  & span{
    font-family: 'Audiowide';
    color: #CF2424;
    font-weight: 600;
  }
  @media(max-width: 500px){
    font-size: ${props => props.fontSize ? props.fontSize : "1rem"};
  }
`;

const StyledNav = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 0;
  align-items: center;
`;


function Navbar() {
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMedia({ maxWidth: '701px' });
  return ((isMobile && location.pathname.includes("career") && location.search.includes("id="))
    ? null
    : <StyledNavbar id="NAV">
        <StyledLogo> ENDY<span>SOFT</span> </StyledLogo>
        <StyledNav>
          {location.pathname !== "/" && <StyledLink to='/'> Home </StyledLink>}
          {location.pathname === "/" && <StyledLink style={{ textAlign: "right", paddingRight: "20px" }} to="/#CTA" > {t("home.topnavbar.contactbtn")} </StyledLink >}
          {location.pathname === "/" && <StyledLink to='/career'> {t("home.topnavbar.careerbtn")} </StyledLink >}
        </StyledNav>
      </StyledNavbar>
  );
}

export default Navbar;
