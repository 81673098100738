import React, { useEffect } from "react";
import { GiCheckMark } from "react-icons/gi";
import Aos from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import commonTexts from "../../i18n/commonTexts.json";
import { StyledUl } from "../StyledComponents";
import logoEndySoft from '../../logos/logo.png';
import teamOutsorcingImg from '../../logos/teamOutsorcingImg.jpg';
import bodyLeasingImg from '../../logos/bodyLeasingImg.jpg';
import { DESCRIPTION_TEXT_COLOR, SUB_DESCRIPTION_TEXT_COLOR } from "../../helpers/colors";

const StyledTile = styled.div`
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    @media(max-width: 1000px){
        flex-direction: column;
    }
`;

const StyledTileText = styled.div`
    margin-right: 50px;
    @media(max-width: 1000px){
        margin: 15px 0;
    }
`;

const StyledHeading = styled.h2`
    font-weight: 600;
    font-size: 35px;
    max-width: 550px;
    margin: 60px auto 20px;
    text-align: center;
    @media(max-width: 1000px){
        max-width: 600px;
    }
    @media(max-width: 600px){
        max-width: 400px;
    }
    @media(max-width: 400px){
        max-width: 300px;
    }
    @media(max-width: 300px){
        max-width: 250px;
    }
`;

const StyledHeadingDescription = styled.p`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 20px;
    opacity: .9;
    text-align: center;
    max-width: 880px;
    margin: 20px auto 60px;
`;

const StyledTileHeading = styled.h3`
    font-weight: 600;
    font-size: 35px;
    letter-spacing: .09rem;
    max-width: 550px;
    margin: 0;
    @media(max-width: 1000px){
        margin: auto;
    }
`;

const StyledDescription = styled.p`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 20px;
    opacity: .9;
    margin:auto;

    @media(max-width: 1000px){
        max-width: 600px;
    }
    @media(max-width: 600px){
        max-width: 400px;
    }
    @media(max-width: 400px){
        max-width: 300px;
    }
`;

const StyledSubDescription = styled.p`
    color: ${SUB_DESCRIPTION_TEXT_COLOR};
    font-size: 20px;
    opacity: .9;
    margin:auto;

    @media(max-width: 1000px){
        max-width: 600px;
    }
    @media(max-width: 600px){
        max-width: 400px;
    }
    @media(max-width: 400px){
        max-width: 300px;
    }
`;

const StyledServiceDescription = styled.p`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 15px;
    opacity: .9;
    margin: 0;
    padding: 0 0 0 10px;
    @media(max-width: 400px){
        font-size: 13px;
    }
`;

const StyledMain = styled.section`
    margin:auto;
    border-bottom: 0.1rem solid;
    border-color: rgba(0,66,101,.4);
    max-width: 1200px;
    ${StyledTile}: nth-child(3) {
        flex-direction: row-reverse;
        ${StyledTileHeading} {
            margin-left: 50px;
        }
        ${StyledDescription} {
            margin-left: 50px;
        }
    }
    @media(max-width: 1000px){
        text-align: center;
        margin: auto;
        padding: 0;
        max-width: 600px;
        ${StyledTile}: nth-child(3) {
            flex-direction: column;
            ${StyledTileHeading} {
                margin: auto;
            }
            ${StyledDescription} {
                margin-left: 0px;
            }
        }
    }
    @media(max-width: 600px){
        max-width: 400px;
    }
    @media(max-width: 400px){
        max-width: 300px;
    }
    @media(max-width: 300px){
        max-width: 250px;
    }
`;

const StyledIcon = styled(GiCheckMark)`
    border-radius: 50%;
    color: #49a291;
    margin: 0;
    padding: 0;
    @media(max-width: 400px){
        width: 10px;
        height: 10px;
    }
`;

const StyledServiceContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    @media(max-width: 1000px){
        justify-content: center;
        max-width: 600px;
        padding-left: 6%;
    }
    @media(max-width: 600px){
        margin-top: 10px;
        margin-left: 0px;
        max-width: 400px;
    }
    @media(max-width: 400px){
        max-width: 300px;
        padding-left: 0%;
    }
`;

const StyledService = styled.div`
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 20px 20px 0 0;
    &: nth-child(1), &: nth-child(4)
    {
        width: 180px;
    }
    @media(max-width: 400px){
        width: 150px;
        margin: 0 0 10px;
        &: nth-child(1), &: nth-child(4)
        {
            width: 150px;
        }
    }
`;

const StyledTIleImg = styled.img`
    width: 400px;
    height: 300px;
    border-radius: 5px; 
    @media(max-width: 400px){
        max-width: 300px;
        max-height: 200px;
    }
    @media(max-width: 300px){
        max-width: 250px;
        max-height: 150px;
    }
`;


function Main() {
    const { t } = useTranslation();

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <StyledMain data-aos="zoom-in" data-aos-delay="100">
{/* 
            <StyledTile data-aos="zoom-in" data-aos-delay="100">
                <StyledTileText>
                    <StyledTileHeading >{t("home.main.second.title")}</StyledTileHeading>
                    <StyledDescription>{t("home.main.second.description")}</StyledDescription>
                    <StyledServiceContainer>
                        <ul>
                            {t("home.main.secod.bullets", { returnObjects: true }).map((el, id) => <li key={id}>{el}</li>)}
                        </ul>
                    </StyledServiceContainer>
                </StyledTileText>
                <StyledTIleImg src={bodyLeasingImg} alt={`Image about ${t("home.main.second.title")}`} />
            </StyledTile>
            <StyledTile data-aos="zoom-in" data-aos-delay="100">
                <StyledTileText>
                    <StyledTileHeading >{t("home.main.third.title")}</StyledTileHeading>
                    <StyledDescription>{t("home.main.third.description")}</StyledDescription>
                    <StyledServiceContainer>
                        <StyledDescription>{t("home.main.third.subdescription")}</StyledDescription>
                        <ul>
                            {t("home.main.third.bullets", { returnObjects: true }).map((el, id) => <li key={id}>{el}</li>)}
                        </ul>

                    </StyledServiceContainer>
                </StyledTileText>
                <StyledTIleImg src={teamOutsorcingImg} alt={`Image about ${t("home.main.second.title")}`} />
            </StyledTile>
            <StyledTile data-aos="zoom-in" data-aos-delay="100">
                <StyledTileText>
                    <StyledTileHeading >{t("home.main.fourth.title")}</StyledTileHeading>
                    <StyledDescription>{t("home.main.fourth.description")}</StyledDescription>
                    <StyledDescription>{t("home.main.fourth.areasHeader")}</StyledDescription>
                    <StyledServiceContainer>
                        <StyledUl>
                            {t("home.main.fourth.areas", { returnObjects: true }).map((area, id) => (
                                <li key={id}>{area}</li>
                            ))}
                        </StyledUl>
                    </StyledServiceContainer>
                </StyledTileText>
                <StyledTIleImg src={commonTexts.imageSoftwareDevelopment} alt={`Image about ${t("home.main.fourth.title")}`} />
            </StyledTile> */}
        </StyledMain>
    )
}

export default Main;
