import React, { useEffect } from "react";
import { GoMail} from "react-icons/go";
import { BsTelephone} from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";
import styled, { css } from 'styled-components';
import { useTranslation } from "react-i18next";
import commonTexts from "../../i18n/commonTexts.json";
import { DESCRIPTION_TEXT_COLOR, MAIN_BLUE_BG_COLOR } from "../../helpers/colors";

const StyledCTABorder = styled.div`
    ${props => props.forModal
        ? `
            margin: auto;
        `
        : `
            margin: 40px auto;
            padding-bottom: 20px;
            border-bottom: 0.1rem solid;
            border-color: rgba(0,66,101,.4);
            max-width: 1200px;
        `};
    @media(max-width: 1000px){
    }
    @media(max-width: 600px){
        margin: 0 auto;
    }
    @media(max-width: 400px){
        margin: 0 10px;
    }
    @media(max-width: 300px){
        max-width: 250px;
    }
`;
const StyledCTA = styled.div`
    max-width: 650px;
`;

const StyledHeading = styled.h2`
    text-align: center;
    font-weight: 600;
    font-size: 35px;
`;

const StyledInformation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin: auto;
    justify-content: center;
    @media(max-width: 400px){
        flex-direction: column-reverse;
    }
`;

const StyledBasicInformation = styled.div`
    width: 50%;
    margin-left: ${props => props.forModal ? "7%" : "15%"};
    @media(max-width: 600px){
        margin-left: 0;
        width: 100%;
    }
`;

const StyledHeadingBasicInformation = styled.h3`
    font-weight: 600;
    @media(max-width: 600px){
        width: 100%;
        text-align: center;
    }
`;

const StyledAdress = styled.div`
    width: 50%;
    margin-left: 15%;
    @media(max-width: 600px){
        width: 100%;
        margin-left: 0;
        margin: 20px auto;
    }
`;

const StyledDescription = styled.p`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 15px;
    opacity: .9;
    max-width: 880px;
    margin: 5px 0;
    @media(max-width: 600px){
        text-align: center;
    }
`;

const StyledRegisterInformation = styled(StyledDescription)`
    font-size: 12px;
    margin: 20px auto;
    @media(max-width: 600px){
        width: 100%;
    }
`;

const StyledInfContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media(max-width: 600px){
        justify-content: center;
    }
`;

const IconCommonStylees = css`
    font-size: 10px;
    background: ${MAIN_BLUE_BG_COLOR};
    width: 16px;
    height: 16px;
    padding: 5px;
    margin: 0 10px 2px 0;
    color: white;
    flex-shrink: 0;
`;

const StyledMailIcon = styled(GoMail)`
    ${IconCommonStylees};
    @media(max-width: 600px){
        margin-left: 10px;
    }
`;

const StyledTelIcon = styled(BsTelephone)`
    ${IconCommonStylees};
`;  

function CTA (props){
    const { t } = useTranslation();
    useEffect(() => {
        !props.forModal && Aos.init({duration: 1000});
    }, []);

    return (
        <StyledCTABorder forModal={props.forModal} data-aos={props.forModal ? "" : "fade-up"} data-aos-delay={props.forModal ? "" : "100"} id="CTA" >
            <StyledCTA>
                <StyledHeading>{t("home.footer.title")}</StyledHeading>
                <StyledInformation>
                    <StyledBasicInformation forModal={props.forModal}>
                        <StyledHeadingBasicInformation>{t("home.footer.basic")}</StyledHeadingBasicInformation>
                        <StyledDescription>{commonTexts.companyName}</StyledDescription>
                        <StyledDescription>{commonTexts.companyId}</StyledDescription>
                        <StyledDescription>{commonTexts.companyVatId}</StyledDescription>
                        <StyledDescription>{`${t("home.footer.databox")}: qbmivre`}</StyledDescription>
                        <StyledHeadingBasicInformation>{t("home.footer.address")}</StyledHeadingBasicInformation>
                        <StyledDescription>Riegrova 832</StyledDescription>
                        <StyledDescription>738 01 Frýdek-Místek</StyledDescription>
                        <StyledDescription>Czech republic</StyledDescription>
                    </StyledBasicInformation>
                    <StyledAdress>
                        <StyledHeadingBasicInformation>{t("home.footer.business")}</StyledHeadingBasicInformation>
                        <StyledInfContainer>
                            <StyledMailIcon />
                            <StyledDescription>{commonTexts.businessEmail}</StyledDescription>
                        </StyledInfContainer>
                        <StyledInfContainer>
                            <StyledTelIcon/>
                            <StyledDescription>{commonTexts.businessNumber}</StyledDescription>
                        </StyledInfContainer>
                        <StyledHeadingBasicInformation>{t("home.footer.bodyleasing")}</StyledHeadingBasicInformation>
                        <StyledInfContainer>
                            <StyledMailIcon />
                            <StyledDescription>{commonTexts.bodyleasingEmail}</StyledDescription>
                        </StyledInfContainer>
                        <StyledInfContainer>
                            <StyledTelIcon/>
                            <StyledDescription>{commonTexts.bodyleasingNumber}</StyledDescription>
                        </StyledInfContainer>
                    </StyledAdress>
                </StyledInformation>
                <StyledRegisterInformation>{t("home.footer.description")}</StyledRegisterInformation>
            </StyledCTA>
        </StyledCTABorder>
    )
}

export default CTA;
