import React from 'react'
import { useTranslation } from 'react-i18next'
import { CzechFlag, EngFlag } from './assets'
import styled from "styled-components"
import Flag from './Flag'
import { useLocation } from 'react-router-dom';

const StyledFlagsContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const I18n = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language)
  }

  const selectedLanguage = i18n.language
  return (location.pathname.includes("career")
    ? null
    : <StyledFlagsContainer>
      <Flag
        image={CzechFlag}
        isSelected={selectedLanguage === 'cs'}
        onClick={() => handleChangeLanguage('cs')}
      />
      <Flag
        image={EngFlag}
        isSelected={selectedLanguage === 'en'}
        onClick={() => handleChangeLanguage('en')}
      />
    </StyledFlagsContainer>
  )
}

export default I18n