import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMedia from 'use-media';
import AboutPosition from './AboutPosition';
import { StyledApplyBackButton, StyledApplyHeadingButton, StyledJobDetails, StyledJobHeadingTitle, StyledJobHeadingTitleWrapper, StyledStickyButtons } from './CareerStyle';
import JobHeading from './JobHeading';
import JobRequired from './JobRequired';
import ModalForm from './Modal';

function JobDetails({jobId, all, visible, onClick}) {
    const { t } = useTranslation();
    const isWide = useMedia({ minWidth: '1001px' });
    let actualJob = all[0];  
    if(jobId===null){
        jobId=visible;
    }

    actualJob = all.find(x => x.id===jobId);

    if(actualJob===undefined){
        actualJob=all[0]
    }

    //Modal State
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return(
        <>
        {(all.length>0) &&       
            <StyledJobDetails >
                {!isWide && <StyledStickyButtons>
                    <StyledApplyBackButton style={{marginLeft: "0"}} onClick={onClick}>{!isWide ? <>&#60;&#60;</> : t("career.jobs.detail.backButton")}</StyledApplyBackButton>
                        <StyledJobHeadingTitleWrapper>
                            <StyledJobHeadingTitle>{actualJob?.title}</StyledJobHeadingTitle>
                        </StyledJobHeadingTitleWrapper>
                    <StyledApplyHeadingButton bgColor={"#5fdd4a"} style={{marginRight: "0"}}onClick={handleOpen}>{t("career.jobs.detail.applyButton")}</StyledApplyHeadingButton>
                </StyledStickyButtons>}
                <JobHeading res={actualJob}  onClick={handleOpen} />
                <ModalForm res={actualJob} open={open} onClose={handleClose} />
                <JobRequired res={actualJob?.skills} />
                {/* <JobNTH res={actualJob?.nth_skills} /> */}
                <AboutPosition description={actualJob?.description} value={t("career.jobs.detail.description")}/>
            </StyledJobDetails>}
        </>

    )
}
export default JobDetails;