import { useTranslation } from 'react-i18next';
import {StyledContainer, StyledGrayDot, StyledColorDot,
        StyledColorDot2, StyledColorDot3, StyledColorDot4,
        StyledColorDot5, StyledRequiredSkills, StyledRequiredSkill,
        StyledDots} from './CareerStyle';

function JobRequired({res}){
  const { t } = useTranslation();
  const skillsList = Object.fromEntries(
    Object.entries(res).sort(([,a],[,b]) => b-a)
  );
  
    return(
        <StyledContainer>
        <h3 style={{fontWeight: "600"}}>{t("career.jobs.detail.requirements")}</h3>
        <StyledRequiredSkills >
          {Object.entries(skillsList).map(([key, value], index) => {
            return (
              <StyledRequiredSkill key={index}>
                <p style={{fontWeight: 600}} >{key}</p>
                <StyledDots >
                  <StyledColorDot Percentage={value} ></StyledColorDot>
                  <StyledColorDot2 Percentage={value} ></StyledColorDot2>
                  <StyledColorDot3 Percentage={value} ></StyledColorDot3>
                  <StyledColorDot4 Percentage={value} ></StyledColorDot4>
                  <StyledColorDot5 Percentage={value} ></StyledColorDot5>
                  <StyledGrayDot ></StyledGrayDot>
                  <StyledGrayDot ></StyledGrayDot>
                  <StyledGrayDot ></StyledGrayDot>
                  <StyledGrayDot ></StyledGrayDot>
                  <StyledGrayDot ></StyledGrayDot>
                </StyledDots>
              </StyledRequiredSkill>
            );
          })}
        </StyledRequiredSkills>
      </StyledContainer>
    )
}
export default JobRequired;