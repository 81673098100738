import React, { useState} from "react";
import { useForm } from 'react-hook-form';
import useMedia from 'use-media';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";
import { StyledCloseModalButton , StyledInputsContainer,
        StyledInputsLabel, StyledInputText, StyledInputValid,
        StyledTextareaLabel, StyledInputFileLabel,StyledInputCVContainer,
        StyledInputTermsLabel, StyledDateLabel, StyledAvailableTermsContainer,
        StyledValidCVContainer, StyledAvailableContainer, StyledErrorSuccessMessage, StyledRequiredFieldsLabel, StyledTermsAgreedContainer, StyledAvailableMdRate, StyledRow, StyledSuffixLabelSpan, StyledApplyButtonForm, StyledMdRateContainer, StyledInputGeneric, StyledTextArea, StyledOptionalRow, StyledRecommendedPerson} from "./CareerStyle";
import Policy from '../policy/PrivacyPolicy.pdf';
import { useTranslation } from "react-i18next";

let validationSchema = yup.object({
  availability: yup.string().required("Date apply from is required"),
  md_rate: yup.number().required("Expected MD rate is required").positive().integer().typeError('Must be a number'),
  name: yup.string().required("First Name is required"),
  surname: yup.string().required("Last name is required"),
  email: yup.string().matches(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Email is required").required(),
  phone: yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Phone number is not valid'),
  cv: yup.mixed()
    .test('fileExist', 'File is required', value => value[0]?.size>0)
    .test('fileSize', "File Size is too large", value => value[0]?.size <= 5000000)
    .test('fileType', "Unsupported File Format", value => ["application/pdf"]
    .includes(value[0]?.type)),
  terms: yup.boolean().oneOf([true],'Accept terms is required'),
}).required();

function Apply(props) {
  const { t } = useTranslation();
  const [isSend, setIsSend] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const isMobile = useMedia({minWidth: '701px'});

  const onSubmit = (data) => {

    let selectedFile = data.cv;
    let file = null;
    // let fileName = "";
    //Check File is not Empty
    if (selectedFile.length > 0) {
        // Select the very first file from list
        let fileToLoad = selectedFile[0];
        // fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            file = fileLoadedEvent.target.result;
            var base64result=file.split(',')[1];
            
            let formData = data;
            formData['position_id'] = props.jobId;

            formData.cv = base64result;
            axios({
              method: "post",
              url: `${process.env.REACT_APP_API_URL}/apply`,
              headers: { "Content-Type":" application/json" },
              data: JSON.stringify(data)
            })
            .then(response => {
              setSuccessMessage(response.data);
              setIsSend(true);
              reset();
            })
            .catch(error => setError( error.response.data ));
          };
          // Convert data to base64
          fileReader.readAsDataURL(fileToLoad);
        }
  }

  
  return (
      <>
        {!isSend && !error && <form action="#" method="post" onSubmit={handleSubmit(onSubmit)} >
          <div style={{display: "flex", flexWrap: "wrap", position: "relative"}}>

            <StyledCloseModalButton onClick={props.onClick} >X</StyledCloseModalButton>

            <input type="checkbox" name="_we_catch_you" value="-1" style={{display: 'none'}} tabIndex="-1" autoComplete="off" />

            <StyledInputsContainer >
              <StyledInputsLabel htmlFor="name"> {t("career.jobs.applyForm.firstName")} * </StyledInputsLabel>
              <StyledInputText name="name" type="text" {...register('name')} />
              <StyledInputValid >{errors.name?.message}</StyledInputValid>
            </StyledInputsContainer>

            <StyledInputsContainer>
              <StyledInputsLabel htmlFor="surname"> {t("career.jobs.applyForm.lastName")} * </StyledInputsLabel>
              <StyledInputText name="surname" type="text" {...register('surname')} />
              <StyledInputValid>{errors.surname?.message}</StyledInputValid>
            </StyledInputsContainer>

            <StyledInputsContainer>
              <StyledInputsLabel htmlFor="email"> {t("career.jobs.applyForm.email")} * </StyledInputsLabel>
              <StyledInputText name="email" type="text" {...register('email')} />
              <StyledInputValid>{errors.email?.message}</StyledInputValid>
            </StyledInputsContainer>
            
            <StyledInputsContainer>
              <StyledInputsLabel htmlFor="phone"> {t("career.jobs.applyForm.phone")} * </StyledInputsLabel>
              <StyledInputText name="phone" type="phone" {...register('phone')} />
              <StyledInputValid>{errors.phone?.message}</StyledInputValid>
            </StyledInputsContainer>

            <StyledValidCVContainer  >
              <StyledInputCVContainer>
                <StyledInputFileLabel style={{}} htmlFor="cv"> {t("career.jobs.applyForm.uploadYourCv")} (pdf/max 5mb) * </StyledInputFileLabel>
                <input style={{border: "0px solid", borderRadius: '3px', outline: 'none', cursor: 'pointer'}} name="cv" type="file" {...register('cv')} />
              </StyledInputCVContainer>
              <StyledInputValid style={{marginTop: "-10px"}} >{errors.cv?.message}</StyledInputValid>
            </StyledValidCVContainer>
            <StyledAvailableMdRate>
              <StyledAvailableContainer>
                <StyledRow>
                  <StyledDateLabel htmlFor="availability">{t("career.jobs.applyForm.availableFrom")} * </StyledDateLabel>
                  <StyledInputGeneric width={"120px"} name="availability" type="date" {...register('availability')} />
                </StyledRow>
                {errors.availability?.message ? <StyledInputValid>{errors.availability?.message}</StyledInputValid> : null}
              </StyledAvailableContainer>
              <StyledAvailableContainer>
                <StyledRow>
                  <StyledDateLabel>{t("career.jobs.applyForm.expectedMdRate")} * </StyledDateLabel>
                  <StyledMdRateContainer>
                    <StyledInputGeneric height={"19px"} width={'50px'} name="md_rate" min={1} type="number" {...register('md_rate')} />
                    <StyledSuffixLabelSpan>CZK</StyledSuffixLabelSpan>
                  </StyledMdRateContainer>
                </StyledRow>
                {errors.expectedMdRate?.message ? <StyledInputValid>{errors.expectedMdRate?.message}</StyledInputValid> : null}
              </StyledAvailableContainer>
            </StyledAvailableMdRate>
            <StyledOptionalRow>
                <StyledDateLabel>{t("career.jobs.applyForm.recommendedPerson")}</StyledDateLabel>
                <StyledRecommendedPerson marginLeft={"8px"} width={'220px'} name="recommended_person" type="text" {...register('recommended_person')} />
            </StyledOptionalRow>

            <div style={{width: "100%"}}>
              <StyledTextareaLabel htmlFor="note"> {t("career.jobs.applyForm.introduceYourself")} </StyledTextareaLabel>
              <StyledTextArea rows={isMobile ? "7" : "4"} name="note" {...register('note')} />
            </div>

            <StyledAvailableTermsContainer>
              <StyledTermsAgreedContainer>
                <input name='terms' type="checkbox" placeholder={t("career.jobs.applyForm.termsPlaceholder")} {...register('terms')} />
                  <StyledInputTermsLabel htmlFor="terms"> {t("career.jobs.applyForm.agreeWithProcessing")}
                    <a style={{color: "black"}} rel='noreferrer' href={Policy} target="_blank">{t("career.jobs.applyForm.personalData")}*</a> 
                  </StyledInputTermsLabel>
                <StyledInputValid>{errors.terms?.message}</StyledInputValid>
              </StyledTermsAgreedContainer>
              <StyledApplyButtonForm type="submit" name="formSubmit">{t("career.jobs.applyForm.apply")}</StyledApplyButtonForm>
            </StyledAvailableTermsContainer>
            <StyledAvailableContainer>
              <StyledRequiredFieldsLabel> * - {t("career.jobs.applyForm.requiredFields")} </StyledRequiredFieldsLabel>
            </StyledAvailableContainer>
          </div>
        </form>}

        {isSend && 
          <StyledErrorSuccessMessage>
            {successMessage}
          </StyledErrorSuccessMessage>
        }
        {error!=="" &&
          <StyledErrorSuccessMessage >
            <p>{t("career.jobs.applyForm.somethingWrongMsg")} :(</p>
          </StyledErrorSuccessMessage>
        }
      </>
  );
}

export default Apply;