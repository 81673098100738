import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

import {StyledAutocomplete} from './CareerStyle';

function SearchBar({value, options, onChange}){
    const { t } = useTranslation();
    return(
        <StyledAutocomplete >
            <Autocomplete
                disablePortal={true}
                autoHighlight={true}
                value={value}
                multiple
                freeSolo={true}
                id="tags-outlined"
                options={options}
                onChange={onChange}
                renderInput={(params) => (
                    <TextField {...params} variant="standard" placeholder={t("career.jobs.list.searchByPlaceholder")} />
                )}
            />
      </StyledAutocomplete>
    )
}

export default SearchBar;