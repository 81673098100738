import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { DESCRIPTION_TEXT_COLOR, MAIN_BLUE_BG_COLOR, MAIN_WHITE_TEXT_COLOR } from "../../helpers/colors";
import bodyLeasingImg from '../../logos/programmer.png';
import softwareDevelopment from '../../logos/software-development.png';
import teamOutsorcingImg from '../../logos/team.png';

const StyledCooperation = styled.section`
    display: flex;
    max-width: 1200px;
    ${props => props.bottomBorder ? "border-bottom: 0.1rem solid;border-color: rgba(0,66,101,.4);" : ""}
    margin:auto;
    @media(max-width: 1000px){
        display: block;
        max-width: 880px;
    }
    @media(max-width: 700px){
        text-align: center;
    }
`;

const StyledHeading = styled.h2`
    padding: 20px;
    font-weight: 600;
    font-size: 35px;
    margin: auto 0;
    text-align: center;
    @media(max-width: 600px){
        margin: auto;
    }
`;


const StyledHeadingOffer = styled(StyledHeading)`
    max-width: unset;
`;

const StyledHeadingDescription = styled.p`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 20px;
    opacity: .9;
    text-align: center;
    max-width: 880px;
    margin: 0 auto 0;
`;

const StyledDescription = styled.p`
    opacity: .9;
    max-width: 880px;
    margin: 6px 0;
`;

const StyledBlueEnumDescription = styled(StyledDescription)`
    text-align: left;
`;

const ServicesDescription = styled.p`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 20px;
    opacity: .9;
    max-width: 880px;
    margin: 6px 0;
`;

const StyledCooperationProp = styled.div`
    font-size: 14px;
    margin: auto;
    margin-left: 90px;
    @media(max-width: 1000px){
        margin-left: 70px;
    }
    @media(max-width: 600px){
        margin-left: 64px;
        font-size: 12px;
    }
`;

const StyledServiceBlock = styled(StyledCooperationProp)`
    margin: 0 20px;
    @media(max-width: 1000px){
        margin: 10px;
        margin-top: ${props => props.biggerMarginTop ? "30px" : "0"};
    }
`;
    
const StyledCooperationPropHeading = styled.h2`
    font-size: 23px;
    font-weight: 600;
    text-transform: uppercase;
    @media(max-width: 600px) {
        font-size: 20px;
    }
`;

const StyledHeadingBlueEnum = styled(StyledCooperationPropHeading)`
    text-align: left;
`;

const StyledCoopIcon = styled.div`
    float: left;
    border: 1px solid ${MAIN_WHITE_TEXT_COLOR};
    width: 70px;
    height: 70px;
    text-align: center;
    border-radius: 50%;
    transition: all .6s ease;
    -moz-transition: all .6s ease;
    -webkit-transition: all .6s ease;

    @media(max-width: 1000px){
        width: 50px;
        height: 50px;
    }

    i {
        font-size: 36px;
        color: #fff;
        line-height: 70px;

        @media(max-width: 1000px){
            font-size: 25px;
            line-height: 50px;
        }
    }
`;

const StyledCooperationContainer = styled.div`
    padding: 10px 20px;
    @media(max-width: 600px){
        padding: 10px;
    }
`;

const StyledServiceContainer = styled.div`
   
`;

const StyledCooperationEnum = styled.div`
    color: ${MAIN_WHITE_TEXT_COLOR};
    & > div:first-of-type {
        padding-top:20px;
    }
    & > div:last-of-type {
        padding-bottom:20px;
    }
`;

const StyledCooperationEnumBlue = styled(StyledCooperationEnum)`
    background-color: ${MAIN_BLUE_BG_COLOR};
`;

const StyledServicesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media(max-width: 1000px){
        display: block;
        padding: 0;
    }
`;

const StyledBulletsContainer = styled.div`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 20px;
    opacity: .9;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    ul {
        margin-left: -20px;
        text-align: left;
    }
    @media(max-width: 1000px){
        justify-content: center;
        padding-left: 6%;
    }
    @media(max-width: 700px) {
        padding-left: 0;
        ul {
            margin-left: auto;
        }
    }
    @media(max-width: 600px){
        margin-top: 10px;
        margin-left: 0px;
    }
    @media(max-width: 400px){
        padding-left: 0%;
    }
    p {
        margin: 0;
    }
`;

const StyledServiceImg = styled.img`
    margin-left: 0;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    display: flex;
    @media(max-width: 700px){
        margin: auto;
    }
    @media(max-width: 400px){
        width: 80px;
        height: 80px;
        max-width: 250px;
        max-height: 200px;
    }
    @media(max-width: 300px){
        max-width: 260px;
        max-height: 150px;
    }
`;

const StyledWhyPartnerContainer = styled.div`
    padding: 0 20px 20px;
    @media(max-width: 1000px) {
        padding: 0 10px 40px;
    }
`;

const StyledWhatWeOfferContainer = styled(StyledWhyPartnerContainer)`
    padding: 0 0 30px;
    p { max-width: 1200px; }
    @media(max-width: 1000px) {
        padding: 0 10px 20px;
    }
    @media(max-width: 700px) {
        padding: 20px 10px;
    }
`;

const getIconByIndex = i => {
    if (i===0) { return "lni-bulb" }
    else if (i===1) { return "lni-package" }
    else if (i===2) { return "lni-plus" }
    return ""
}

function Cooperation() {
    const { t } = useTranslation();
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <StyledCooperation bottomBorder={true} data-aos="fade-up" data-aos-delay="100">
                <StyledWhyPartnerContainer>
                    <StyledHeading>{t("home.main.cooperation.title")}</StyledHeading>
                    <StyledHeadingDescription>{t("home.main.cooperation.description")}</StyledHeadingDescription>
                </StyledWhyPartnerContainer>
                <StyledCooperationEnumBlue>
                    {t("home.main.cooperation.conditions", { returnObjects: true }).map((condition, id) => (
                        <StyledCooperationContainer key={condition.key}>
                            <StyledCoopIcon>
                                <i className={getIconByIndex(id)}></i>
                            </StyledCoopIcon>
                            <StyledCooperationProp>
                                <StyledHeadingBlueEnum>{condition["head"]}</StyledHeadingBlueEnum>
                                <StyledBlueEnumDescription>{condition["description"]}</StyledBlueEnumDescription>
                            </StyledCooperationProp>
                        </StyledCooperationContainer>
                    ))}
                </StyledCooperationEnumBlue>
            </StyledCooperation>
            <StyledCooperation data-aos="fade-up" data-aos-delay="100">
                <StyledWhatWeOfferContainer>
                    <StyledHeadingOffer>{t("home.main.whatWeOffer.title")}</StyledHeadingOffer>
                    <StyledHeadingDescription >{t("home.main.whatWeOffer.description")}</StyledHeadingDescription>
                </StyledWhatWeOfferContainer>
            </StyledCooperation>
            <StyledCooperation data-aos="fade-up" data-aos-delay="100">
                <StyledServicesContainer>
                    <StyledServiceContainer>
                        <StyledServiceBlock>
                            <StyledServiceImg src={teamOutsorcingImg} />
                            <StyledCooperationPropHeading>{t("home.main.teamOutsorcing.title")}</StyledCooperationPropHeading>
                            <ServicesDescription>{t("home.main.teamOutsorcing.description")}</ServicesDescription>
                            <StyledBulletsContainer>
                                <ul>
                                    {t("home.main.teamOutsorcing.bullets", { returnObjects: true }).map((el, id) => <li key={id}><StyledDescription>{el}</StyledDescription></li>)}
                                </ul>
                            </StyledBulletsContainer>
                        </StyledServiceBlock>
                    </StyledServiceContainer>
                    <StyledServiceContainer>
                        <StyledServiceBlock biggerMarginTop={true}>
                            <StyledServiceImg src={bodyLeasingImg} />
                            <StyledCooperationPropHeading>{t("home.main.bodyLeasing.title")}</StyledCooperationPropHeading>
                            <ServicesDescription>{t("home.main.bodyLeasing.description")}</ServicesDescription>
                            <StyledBulletsContainer>
                                <ul>
                                    {t("home.main.bodyLeasing.bullets", { returnObjects: true }).map((el, id) => <li key={id}><StyledDescription>{el}</StyledDescription></li>)}
                                </ul>
                            </StyledBulletsContainer>
                        </StyledServiceBlock>
                    </StyledServiceContainer>
                    <StyledServiceContainer>
                        <StyledServiceBlock biggerMarginTop={true}>
                            <StyledServiceImg src={softwareDevelopment} />
                            <StyledCooperationPropHeading>{t("home.main.softwareConsulting.title")}</StyledCooperationPropHeading>
                            <ServicesDescription>{t("home.main.softwareConsulting.description")}</ServicesDescription>
                        </StyledServiceBlock>
                    </StyledServiceContainer>
                </StyledServicesContainer>
            </StyledCooperation>
        </>
    )
}

export default Cooperation;