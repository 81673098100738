import React from "react";
// import { HashLink as Link } from 'react-router-hash-link';
// import { useLocation } from 'react-router-dom';
import Content from '../../api/homePage.json';
import { BsLinkedin } from  "react-icons/bs";
import { FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";
import Policy from '../../policy/PrivacyPolicy.pdf' 
import styled from 'styled-components';
import { MAIN_BLUE_BG_COLOR } from "../../helpers/colors";
import { useTranslation } from "react-i18next";
import commonTexts from "../../i18n/commonTexts.json";
import I18n from "../../i18n/I18n";

// const StyledLink = styled(Link)`
//     text-decoration: none;
//     font-size: 30px;
//     width: 30px;
//     height: 30px;
//     position: absolute;
//     background: gray;
//     border-radius: 50%;
//     flex-shrink:0;
//     color: #fff;
//     padding-bottom: 5px;
//     top: -300px;
//     right: 30px;
//     text-align: center;
//     &:hover {
//     background: #364fd3;
//     }
// `;

const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 20px auto;
    clear: both;
    position: absolute;
    bottom: 0;
    height: 4rem;
    width: 100%;
    max-width: 1200px;
    @media(max-width: 1000px){
        flex-direction: column-reverse;
    }
`;

const StyledContainer = styled.div`
    padding-bottom: 4rem; 
    max-width: 1200px;
    @media(max-width: 1000px){
        margin-top: 20px;
        padding-bottom: 8rem; 
    }
`;

const StyledCopyrights= styled.p`
    margin: 0;
    text-align: left;
    @media(max-width: 1000px){
        font-size: 12px;
        margin: 0 auto;
    }
`;

const StyledSocialIconLI = styled(BsLinkedin)`
    color: ${MAIN_BLUE_BG_COLOR};
    width: 20px;
    height: 20px;
    `;

const StyledSocialIconFB = styled(FaFacebookSquare)`
    color: ${MAIN_BLUE_BG_COLOR};
    width: 23px;
    height: 23px;
    `;

const StyledSocialIconTW = styled(FaTwitterSquare)`
    color: ${MAIN_BLUE_BG_COLOR};
    width: 23px;
    height: 23px;
`;


const StyledSocialIconContainer = styled.div`
    margin: 0 10px 0 0;
`;

const StyledIconsContainer = styled.div`
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    @media(max-width: 1000px){
        margin: 15px auto;
    }
`;

const StyledPolicy = styled.div`
    margin: 0;
    @media(max-width: 1000px){
        margin: 0 auto;
        font-size: 12px;
    }
`;

const StyledCopyrightContainer = styled.div`
    align-items: center;
    margin: 0;
    display: flex;
    @media(max-width: 1000px) {
        margin: 0px auto;
    }
`;

function Footer (){
    const { t } = useTranslation();
    return (
        <StyledContainer>
            <StyledFooter>
                {/* {location.pathname ==="/" && <StyledLink to="/#NAV" > &#8657; </StyledLink >} */}
                <StyledCopyrightContainer>
                    <StyledCopyrights>© {new Date().getFullYear()} EndySoft, {t("footer.allRightsReserved")}</StyledCopyrights>
                    {/* <I18n /> */}
                </StyledCopyrightContainer>
                <StyledIconsContainer >
                    <StyledSocialIconContainer>
                        <a href={commonTexts.companyLinkedin}><StyledSocialIconLI /></a>
                    </StyledSocialIconContainer>
                    <StyledSocialIconContainer>
                        <a href={commonTexts.companyFacebook}><StyledSocialIconFB /></a>
                    </StyledSocialIconContainer>
                    <StyledSocialIconContainer>
                        <a href={commonTexts.companyTwitter}><StyledSocialIconTW /></a>
                    </StyledSocialIconContainer>
                </StyledIconsContainer>
                <StyledPolicy>Privacy policy,  <a rel='noreferrer' href={Policy} target="_blank" >learn more.</a></StyledPolicy>
            </StyledFooter>
        </StyledContainer>
    )
}

export default Footer;
