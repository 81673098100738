import React from "react";

function Page404 (){
    return (
        <div style={{widht: "100vw", height: "44vh", textAlign: "center", paddingTop: "20%",fontSize: "50px"}} >
            Page not found.
        </div>
    )
}

export default Page404;