import useMedia from 'use-media';
import { StyledHeadingContainer, StyledJobHeadingTitle, StyledApplyHeadingButton, StyledJobHeadingApplyButtonWrapper, StyledJobHeadingTitleWrapper } from './CareerStyle';
import { useTranslation } from "react-i18next";

function JobHeading({ res, onClick }) {

    const isWide = useMedia({ minWidth: '1001px' });
    const { t } = useTranslation();

    return (
        <>
            {isWide && <StyledJobHeadingTitleWrapper>
                <StyledJobHeadingTitle>{res?.title}</StyledJobHeadingTitle>
                <StyledApplyHeadingButton onClick={onClick}>{t("career.jobs.detail.applyButton")}</StyledApplyHeadingButton>
            </StyledJobHeadingTitleWrapper>}
            <StyledHeadingContainer >
                <div style={{ flexShrink: '0', maxWidth: "350px", margin: '0px' }}>
                    {res?.contract !== "" && (<p style={{ fontSize: "18px" }} >
                        <span style={{ fontWeight: "600" }} >{t("career.jobs.detail.contract")}: </span>
                        {res?.contract}
                    </p>)}
                    {res?.location !== "" && (<p style={{ fontSize: "18px" }} >
                        <span style={{ fontWeight: "600" }} >{t("career.jobs.detail.location")}: </span>
                        {res?.location}
                    </p>)}
                </div>
                <div style={{ flexShrink: '0', maxWidth: "350px", margin: '0px' }}>
                    {res?.start !== "" && (<p style={{ fontSize: "18px" }} >
                        <span style={{ fontWeight: "600" }} >{t("career.jobs.detail.start")}: </span>
                        {res?.start}
                    </p>)}
                    {res?.length !== "" && (<p style={{ fontSize: "18px" }} >
                        <span style={{ fontWeight: "600" }} >{t("career.jobs.detail.length")}: </span>
                        {res?.length}
                    </p>)}
                </div>

            </StyledHeadingContainer>
        </>
    )
}

export default JobHeading;