import styled from "styled-components";
import { MAIN_PRIMARY_COLOR, MAIN_WHITE_TEXT_COLOR } from "../helpers/colors";

export const StyledJobList = styled.ul`
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 83vh;
  padding: 0 5px 0 0;
  @media(max-width: 1000px){
    height: 70vh;
  }
  @media(max-width: 500px){
    margin-top: 10px;
  }
  @media(max-width: 400px){
    padding: 0;
  }
`;

export const StyledJobListContainer = styled.div`
  position: relative;
  width: 50%;
  @media(max-width: 1000px){
    width: 100%;
    margin: 0 5px;
  }
  @media(max-width: 700px){
    margin: -10px 14px 0;
  }
  @media(max-width: 700px){
    margin: 0;
  }
`;

export const StyledJobListItem = styled.li`
  cursor: pointer;
  border: none;
  position: relative;
  margin: 0 10px 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.selected ? MAIN_PRIMARY_COLOR : "#a9a9a91c"};
  color: ${props => props.selected ? "white" : "unset"};
  border-radius: 5px;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  @media(max-width: 500px){
    padding: 5px;
  }
  @media(max-width: 400px){
    margin: 0 0 5px;
    padding: 5px 1px;
  }
  @media(max-width: 300px){
    p {
      font-size: 7px;
    }
  }
`;

export const StyledJobListParagraph = styled.p`
  position: relative;
  padding: 0 5px;
  font-size: 14px;
  text-align: center;

  @media(max-width: 1150px){
    font-size: 12px;
  }
  @media(max-width: 1000px){
    font-size: 14px;
  }
  @media(max-width: 500px){
    font-size: 9px;
  }
`;

export const StyledJobListTitle = styled(StyledJobListParagraph)`
  float: left;
  width: 35%;
  padding: 0;
  white-space: nowrap;
  text-align: right;
`;

export const StyledJobListSalary = styled(StyledJobListParagraph)`
  width: 25%;
  padding: 0;
  @media(max-width: 1000px){
    width: 30%;
  }
  @media(max-width: 700px){
    width: 50%;
  }
  @media(max-width: 550px){
    width: 60%;
  }
`;

export const StyledJobListLocation = styled(StyledJobListParagraph)`
  float: left;
  width: 25%;
`;
export const StyledJobListContract = styled(StyledJobListParagraph)`
  float: left;
  width: 20%;
`;
 export const StyledJobListRemote = styled(StyledJobListParagraph)`
  float: left;
  width: 20%;
`;

export const StyledJobHeadingTitle = styled.span`
  margin: 0;
`;

export const StyledJobHeadingTitleWrapper = styled.section`
display: flex;
justify-content: space-between;
font-size: 35px;
@media(max-width: 1000px){
  margin: auto;
  text-align: center;
  font-size: 30px;
}
@media(max-width: 800px){
  font-size: 25px;
}
@media(max-width: 700px){
  font-size: 22px;
}
@media(max-width: 600px){
  font-size: 18px;
  font-weight: 600;
}
@media(max-width: 500px){
  font-size: 16px;
}
@media(min-width: 1001px){
  position: sticky;
  top: 0;
  background: #f8f9fa;
  z-index: 2;
}
`;

export const StyledJobDetails = styled.div`
  box-sizing: border-box;
  padding-left: 10px;
  width: 47%;
  padding-top: 20px;
  @media(max-width: 1000px){
    padding-top: 0;
    width: 100%;
    margin: 0 5px;
  }
  @media(max-width: 700px) {
    margin: 0 10px;
    padding-left: 0;
  }
  @media(max-width: 400px){
    padding-left: 0;
    margin: 0;
  }
`;

export const StyledGrayDot = styled.div`
  opacity: .4;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px 0 0;
  background: #b5aeae;
  padding: 0;
  z-index: -1;
  @media(max-width: 600px){
    width: 10px;
    height: 10px;
  }
`;

export const StyledColorDot = styled.div`
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: red;
  margin: 0 5px 0 0;
  z-index: 1;
  visibility: ${(props)=> props.Percentage >= 10 ? "visible" : "hidden" };
  @media(max-width: 600px){
    width: 10px;
    height: 10px;
  }
`;

export const StyledColorDot2 = styled(StyledColorDot)`
  left: 20px;
  visibility: ${(props)=> props.Percentage > 30 ? "visible" : "hidden" };
  @media(max-width: 600px){
    left: 15px;
  }
`;

export const StyledColorDot3 = styled(StyledColorDot)`
  left: 40px;
  visibility: ${(props)=> props.Percentage > 50 ? "visible" : "hidden" };
  @media(max-width: 600px){
    left: 30px;
  }
`;

export const StyledColorDot4 = styled(StyledColorDot)`
  left: 60px;
  visibility: ${(props)=> props.Percentage > 70 ? "visible" : "hidden" };
  @media(max-width: 600px){
    left: 45px;
  }
`;

export const StyledColorDot5 = styled(StyledColorDot)`
  left: 80px;
  visibility: ${(props)=> props.Percentage > 90 ? "visible" : "hidden" };
  @media(max-width: 600px){
    left: 60px;
  }
`;

export const StyledPageBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  @media(max-width: 400px) {
    padding: 5px;
  }
`;

export const StyledTopPage = styled(StyledPageBody)`
  border-bottom: 0.1rem solid;
  border-color: rgba(255,255,255,0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:100%;
  margin: 0;
  padding: 0;
  @media(max-width: 1000px){
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin: 0 auto;
  }
`;

export const StyledSalary = styled.div`
  width: 28%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  margin-right: 30px;
  @media(max-width: 1000px){
    width:90%;
    margin: 30px auto -15px;
  }
  @media(max-width: 500px){
    width:80%;
    margin: 30px auto -20px;
    flex-direction: column-reverse;
  }
`;

export const StyledSalaryParagraph = styled.p`
  margin-right: 20px;
  flex-shrink: 0;
  @media(max-width: 500px){
    margin-top: -17px;
    margin-left: 0;
    font-size: 13px;
  }
`;

export const StyledNoResults = styled.p`
  margin: 100px auto;
  text-align: right;
  font-size: 30px;
  height: 55vh;
  @media(max-width: 500px){
    width: 50%;
    text-align: center;
    font-size: 25px;
  }
`;

export const StyledRequiredSkills = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0;
  @media(max-width: 700px) {
    margin-left: 14%;
  }
  @media(max-width: 500px) {
    margin-left: 11%;
  }
  @media(max-width: 350px) {
    margin-left: 9%;
  }
`;

export const StyledRequiredSkill = styled.div`
  flex: 0 0 25%;
  margin: 10px 0px;
  max-width: 25%:
  display: flex;
  justify-content: flex-start;

  @media(max-width: 600px){
    flex: 0 0 33.3%;
  }
  @media(max-width: 300px){
    flex: 0 0 50%;
  }
`;
  
export const StyledDots = styled.div`
  display: flex;
  position: relative;
`;

export const StyledButton = styled.button`
  width: 70px;
  height: 50px;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  color: ${MAIN_WHITE_TEXT_COLOR};
  font-weight: 400;
  border: none;
  background: ${(props)=> props.clicked ==="remote" ? "#364fd3" : MAIN_PRIMARY_COLOR };
  &:hover {
    background: ${(props)=> props.clicked ==="remote"  ? MAIN_PRIMARY_COLOR : "#364fd3" };
  };
`;

export const StyledSortButton = styled(StyledButton)`
  height: 30px;
  width: 50%;
  @media(max-width: 500px){
    font-size: 10px;
    height: 15px;
  }
`;



export const StyledApplyButton = styled.button`
  width: 100px;
  height: 50px;
  background: ${props => props.bgColor ? props.bgColor : MAIN_PRIMARY_COLOR};
  text-decoration: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
  color: ${MAIN_WHITE_TEXT_COLOR};
  &:hover {
    background: #364fd3;
  };
  @media(max-width: 700px){
    height: 40px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  @media(max-width: 400px){
    height: 30px;
  }
`;

export const StyledApplyButtonForm = styled(StyledApplyButton)`
  margin: 0;
  margin-right: 10px;
  margin-top: 4px;
`;

export const StyledApplyHeadingButton = styled(StyledApplyButton)`
  text-transform: uppercase;
  width: 100px;
  height: 40px;
  font-weight: 600;
  font-size: 18px;
  margin: auto 0;
  color: ${MAIN_WHITE_TEXT_COLOR};
  @media(max-width: 1000px){
    width: 150px;
    height: 50px;
    font-size: 16px;
  }
  @media(max-width: 600px){
    width: 130px;
    height: 40px;
  }
  @media(max-width: 500px){
    width: 80px;
    height: 40px;
    font-size: 14px;
  }
`;

export const StyledApplyBackButton = styled(StyledApplyHeadingButton)`
  background: initial;
  &:hover {
    background: initial;
  };
  color: ${MAIN_PRIMARY_COLOR};
  @media(max-width: 1000px){
    font-size: 26px;
  }
`;

export const StyledJobTableHeaderContract = styled.div`
  background-color: ${MAIN_PRIMARY_COLOR};
  text-align: center;
  color: ${MAIN_WHITE_TEXT_COLOR};
  height: 30px;
  float: left;
  width: 20%;
  @media(max-width: 500px){
    font-size: 10px;
    height: 15px;
  }

`;

export const StyledJobTableHeaderEl = styled.div`
  background-color: ${MAIN_PRIMARY_COLOR};
  color: ${MAIN_WHITE_TEXT_COLOR};
  height: 30px;
  @media(max-width: 500px){
    font-size: 10px;
    height: 15px;
  }
  text-align: center;
  float: left;
  width: 25%;

`;

export const StyledJobTablePosition = styled(StyledJobTableHeaderEl)`
  text-align: right;
  float: left;
  width: 35%;
`;

export const StyledJobTableHeaderLength = styled(StyledJobTableHeaderEl)`
  float: left;
  width: 20%;
`;

export const StyledTableHeaderContainer = styled.div`
  margin-left: 10px;
  position: sticky;
  top: 0px;
  z-index: 3;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 0;
  width:100%;

  & > p {
    font-size: 18px;
    background-color: ${MAIN_PRIMARY_COLOR};
    color: ${MAIN_WHITE_TEXT_COLOR};
    height: 30px;
    @media(max-width: 500px){
      font-size: 10px;
      height: 15px;
    }
  }

  & > p:first-of-type {
    border-top-left-radius: 6px;
  }
  & > p:last-of-type {
    border-top-right-radius: 6px;
  }
  @media(max-width: 600px){
    width: 101%;
  }
  @media(max-width: 350px){
    margin-left: 0;
  }
`;

export const StyledContainer = styled.section`
  width: 100%;
  padding: 15px 0;
  border-top: 0.1rem solid;
  border-color: rgba(0,66,101,1);
`;

export const StyledHeadingContainer = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #f8f9fa;
  @media(max-width: 1000px){
    flex-wrap: wrap;
    position: relative;
  }
`;

export const StyledAutocomplete = styled.div`
  background: white;
  position: relative;
  width: 48%;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 14px;
  @media(max-width: 1000px){
    width: 93%;
    margin-Left: 12px;
  }
  @media(max-width: 700px){
    width: 94%;
    margin-Left: 10px;
  }
  @media(max-width: 600px){
    width: 91%;
    margin-left: 10px;
  }
  @media(max-width: 400px){
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledCloseModalButton = styled.button`
  position: absolute;
  right: -10px;
  top: -20px;
  border: none;
  background: ${MAIN_WHITE_TEXT_COLOR};
  cursor: pointer;
`;

export const StyledInputsContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 80px;
  box-sizing: border-box;
  @media(max-width: 700px){
    width: 100%;
    height: 50px;
  }
`;

export const StyledInputsLabel = styled.label`
  font-size: 14px;
  background: white;
  margin-bottom: -10px;
  z-index: 1;
  margin-left: 10px;
  @media(max-width: 700px){
    font-size: 12px;
  }
`;

export const StyledDateLabel = styled.label`
  margin: auto 2px auto 0;
  background: white;
  z-index: 1;
  @media(max-width: 600px){
    font-size: 11px;
  }
`;

export const StyledInputText = styled.input`
  margin-left: 0;
  width: 95%;
  height: 40px;
  padding-left: 10px;
  border: 1px solid black;
  outline: none;
  @media(max-width: 700px){
    height: 30px;
    width: 99%;
  }
`;

export const StyledTermsAgreedContainer = styled.div`
  margin-left: 0px;
  margin-bottom: 8px;
  height: 22px;
`;

export const StyledInputGeneric = styled.input`
  margin: 0;
  ${props => props.width ? `width: ${props.width}` : ""};
  ${props => props.marginLeft ? `margin-left: ${props.marginLeft}` : ""};
  height: ${props => props.height ? props.height : "22px"};
  padding-left: 6px;
  border: 1px solid black;
  outline: none;
`;

export const StyledRecommendedPerson = styled(StyledInputGeneric)`
  width: 220px;
  @media(max-width: 500px) {
    width: 180px;
  }
  @media(max-width: 400px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 97%;
  border-top-width: 1px;
`;


export const StyledInputValid = styled.p`
  color: red;
  margin-left: 0;
  @media(max-width: 700px){
    font-size: 12px;
  }
`;

export const StyledTextareaLabel = styled.label`
  @media(max-width: 700px){
    font-size: 12px;
  }
`;

export const StyledInputFileLabel = styled.label`
  @media(max-width: 700px){
    font-size: 12px;
    margin-left: 70px;
  }
  @media(max-width: 520px){
    font-size: 12px;
    margin-left: 20px;
  }
  @media(max-width: 370px){
    font-size: 12px;
    margin-left: 0;
  }
`;

export const StyledInputTermsLabel = styled.label`
  @media(max-width: 700px){
    font-size: 11px;
  }
`;

export const StyledInputCVContainer = styled.div`
  margin-left: 0;
  width: 97.5%;
  height: 80px;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  @media(max-width: 700px){
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledStickyButtons = styled.div`
  display: flex;
  margin-bottom: 15px;
  position: sticky;
  top: 81px;
  z-index: 2;
  background: #f8f9fa;
  @media (max-width: 700px) {
    top: 0;
    margin-top: 10px;
  }
  @media (max-width: 300px) {
    margin-bottom: 5px;
    margin-top: 0;
  }
`;

export const StyledVacatsPopup = styled.span`
  visibility: hidden;
  width: 100px;
  border-radius: 15px;
  font-size: 10px;
  line-height: 18px;
  font-weight: 400;
  background: ${MAIN_WHITE_TEXT_COLOR};
  text-align: center;
  position: absolute;
  z-index: 2;
  top: -18px;
  right: -10px;
  color: gray;
`;


export const StyledVacats = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: ${MAIN_WHITE_TEXT_COLOR};
  text-align:center;
  background: ${(props)=> (props.vacatsLeft === 1 || props.vacatsLeft === "1" )? "red" : "green" };
  font-size: 12px;
  &:hover span,
  :active span{
    visibility:visible;
  }

  @media(max-width: 500px){
    font-size: 10px;
  }
`;

export const StyledDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  &:hover div,
  :active div{
    display:block;
  }
`;

export const StyledDropButton = styled.button`
  width: 50px;
  height: 30px;
  background: ${MAIN_PRIMARY_COLOR};
  text-decoration: none;
  border: none;
  border-radius: 15px 15px 0 0px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
  color: ${MAIN_WHITE_TEXT_COLOR};
  margin-right: 20px;
  &:hover {
    background: #364fd3;
  };
  @media(max-width: 1000px){
    width: 40px;
    height: 20px;
    font-size: 14px;
    margin: 0 auto;
  }
  @media(max-width: 500px){
    width: 30px;
    height: 15px;
    font-size: 11px;
  }
`;

export const StyledDropContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 4;
  @media(max-width: 1000px){
    width: 60px;
  }
  @media(max-width: 500px){
    width: 40px;
  }
  `;
  
  export const StyledDropListItem = styled.p`
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background: #364fd3;
    color: ${MAIN_WHITE_TEXT_COLOR};
  };
  @media(max-width: 1000px){
    font-size: 14px;
  }
  @media(max-width: 500px){
    font-size: 11px;
  }
`;

export const StyledRequiredFieldsLabel = styled.p`
  font-size: 11px;
`;

export const StyledAvailableTermsContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media(max-width: 500px){
    height: 80px;
    flex-direction: row-reverse;
  }
`;

export const StyledMdRateContainer = styled.div`
  margin: 0;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  &:first-child { margin-left: 0; }
  &:last-child { margin-right: 0; }
  @media(max-width: 700px) {
    justify-content: space-between;
    margin-bottom: 6px;
    font-size: 12px;
  }
`;

export const StyledValidCVContainer = styled.div`
  height: 110px;
  width: 100%;
  @media(max-width: 700px){
    height: 100px;
  }
`;

export const StyledOptionalRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 12px;
  @media(max-width: 700px){
    font-size: 12px;
    margin-bottom: 0;
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
  }
  @media(max-width: 400px){
    display: block;
  }
`;

export const StyledAvailableMdRate = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 12px;
  @media(max-width: 700px){
    margin-bottom: 0;
    display: block;
  }
`;

export const StyledAvailableContainer = styled.div`
  margin: 0;
  @media(max-width: 700px){
    margin-left: 0;
  }
`;

export const StyledSuffixLabelSpan = styled.span`
  margin: auto;
  margin-left: 2px;
  font-size: 16px;
  @media(max-width: 600px){
    font-size: 11px;
  }
`;

export const StyledErrorSuccessMessage = styled.div `
  text-align: center;
  padding-top: 30%;
  font-size: 40px;
  @media(max-width: 700px){
    font-size: 25px;
  }
`;

export const StyledForm = styled.form`
@media(max-width: 500px){
  overflow-y: scroll;
  overflow-x: hidden;
}

`;
