import { Link } from "react-router-dom";
import {
  //  StyledVacats, StyledVacatsPopup
  StyledJobListContract, StyledJobListItem, StyledJobListLocation, StyledJobListRemote, StyledJobListTitle
} from './CareerStyle';

function JobList ({res, onClick, mobile, selectedItemId}){
    return(
        <>
        {res.map((job, index) => (
            <Link style={{ textDecoration: "none", color: "#50555a" }} to={`/career?id=${job.id}`} key={job.id} >
              <StyledJobListItem
                selected={job.id === selectedItemId}
                id={job.id}
                onClick={onClick}
                key={job.id}
                >
                {mobile && <StyledJobListTitle >
                  {job?.title!=="" &&(job.title.length > 28 ? job.title.slice(0, 28 - 1) + "…" : job.title)}
                </StyledJobListTitle>}
                {!mobile && <StyledJobListTitle >
                  {job?.title!=="" &&(job.title.length > 23 ? `${job.title.slice(0, 23 - 1)}…` : job.title)}
                </StyledJobListTitle>}
                {<StyledJobListContract >
                  {(job?.contract!=="") && (job.contract)}
                </StyledJobListContract>}
                <StyledJobListRemote>
                  {job?.length}
                </StyledJobListRemote>
                <StyledJobListLocation>
                  {job?.location}
                </StyledJobListLocation>
              </StyledJobListItem>
            </Link>
        ))}
        </>
    )
}

export default JobList;

