import {StyledContainer} from './CareerStyle';
import styled from "styled-components";
import ReactMarkdown from 'react-markdown';
const j2m = require('jira2md');

export const StyledAboutPosition = styled.p`
    margin-left: 0;
    white-space: pre-line;
    vertical-align: bottom;
`;

const PLUS_SYMBOL_PLACEHOLDER = "czzPWqS9Yc7c" // random string just for replacing plus (+) characters before converting to md via buggy jira2md library which renders + as <ins> tags

function AboutPosition({description, value}){
    const fixedMd = j2m
        .to_markdown(description.replaceAll("+", PLUS_SYMBOL_PLACEHOLDER)) // replace + symbols by temporary placeholder
        .replaceAll(PLUS_SYMBOL_PLACEHOLDER, "+"); // in final markdown replace back temporary placeholder by + symbols
    return(
        <StyledContainer>
            <h3 style={{fontWeight: "600"}}>{value}</h3>
            <ReactMarkdown className={"md-description"} children={fixedMd} />
        </StyledContainer>
    )
}

export default AboutPosition;