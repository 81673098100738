import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import CTAModal from "../CTAModal";
import CTA from "./CTA";
import { DESCRIPTION_TEXT_COLOR, MAIN_PRIMARY_COLOR, MAIN_WHITE_TEXT_COLOR } from "../../helpers/colors";
import headerPicture from '../../logos/hero-1.png';
import { StyledLogo } from "../Navbar/Navbar";

const StyledHeader = styled.section`
    text-align: center;
    max-width: 1200px;
    margin:auto;
    border-bottom: 0.1rem solid;
    border-color: rgba(0,66,101,.4);
    @media(max-width: 1000px){
        max-width: 880px;
    }
    @media(max-width: 600px){
        padding: 0 10px
    }
`;

const StyledHeading = styled.h1`
    font-weight: 600;
    font-size: 40px;
    letter-spacing: .09rem;
    max-width: 550px;
    margin: 20px auto 20px;
`;

const StyledDescription = styled.div`
    color: ${DESCRIPTION_TEXT_COLOR};
    font-size: 20px;
    opacity: .9;
    max-width: 750px;
    margin: 30px auto;
`;

const StyledButton = styled.button`
    width: 150px;
    height: 50px;
    background: ${MAIN_PRIMARY_COLOR};
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: ${MAIN_WHITE_TEXT_COLOR};
    font-weight: 600;
    &:hover {
        background: #364fd3;
    };
`;

const StyledHeaderImg = styled.img`
    display: block;
    @media(max-width: 600px){
        max-width: 400px;
    }
    @media(max-width: 400px){
        max-width: 300px;
    }
    @media(max-width: 300px){
        max-width: 250px;
    }
`;

function Header() {
    const { t } = useTranslation();

    //Modal State
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <StyledHeader data-aos="fade-down" >
            <StyledHeading>{t("home.header.main")}</StyledHeading>
            <StyledButton onClick={handleOpen}>
                {t("home.header.button")}
            </StyledButton>
            <StyledHeaderImg src={headerPicture} />
            <StyledDescription>
                {t("home.header.second.begin")}
                <StyledLogo fontSize={"1em"}>
                    ENDY
                    <span>SOFT</span>
                </StyledLogo>
                {t("home.header.second.end")}
            </StyledDescription>
            <CTAModal open={open} onClose={handleClose}>
                <CTA forModal={true} />
            </CTAModal>
        </StyledHeader>
    )
}

export default Header;