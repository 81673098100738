import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Apply from './Apply';
import { MAIN_WHITE_TEXT_COLOR } from '../helpers/colors';

//ModalStyle
export const COMMON_MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  backgroundColor: MAIN_WHITE_TEXT_COLOR,
  borderRadius: "20px",
  boxShadow: "24",
  p: 4,
  color: "black",
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width:700px)']:{
    width: 400,
    height: 600,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width:520px)']:{
    width: 300,
    height: 620,
    p: 3,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-width:370px)']:{
    width: 220,
    height: 620,
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:640px)']:{
    top: "60%",
    left: "50%",
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:540px)']:{
    top: "70%",
    left: "50%",
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:440px)']:{
    top: "80%",
    left: "50%",
  },
  // eslint-disable-next-line no-useless-computed-key
  ['@media (max-height:390px)']:{
    top: "100%",
    left: "50%",
  },
  ['@media (max-height:300px)']:{
    top: "260%",
    left: "50%",
  },
};

function ModalForm({open, onClose, res}){
    return(
        <Modal
          open={open}
          onClose={(res && res.id === "-1") ? () => ({}) : onClose} // don't call onClose when click outside of modal for jobId = -1
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{overflowY: "scroll", overflowX: "hidden"}}
        >
          <Box sx={COMMON_MODAL_STYLE}>
              <Apply onClick={onClose} jobTitle={res?.title} jobId={res?.id} />
          </Box>
        </Modal>
    )
}

export default ModalForm;