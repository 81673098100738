import React from 'react';
import GlobalStyles from './styles/GlobalStyles'
import Career from './components/Career';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Page404 from './components/404/Page404';
import Home from './components/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './i18n';

function App (){
    return (
        <BrowserRouter>
            <GlobalStyles />
            <Navbar />
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/career' element={<Career />} />
                <Route path="*" element={<Page404 />} />  
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App;