import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslations from "./locales/en.json";
import csTranslations from "./locales/cs.json";

const resources = {
  en: {
    translations: enTranslations,
  },
  cs: {
    translations: csTranslations,
  },
};

const i18nConfig = {
  resources: resources,
  fallbackLng: 'en',
  defaultNS: 'translations'
}

i18n
  .use(initReactI18next)
  .init(i18nConfig)

export default i18n