import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import useMedia from 'use-media';
import Skills from '../api/skilsList.json';
import { StyledJobList, StyledJobListContainer, StyledJobListContract, StyledJobListLocation, StyledJobListRemote, StyledJobListTitle, StyledNoResults, StyledPageBody, StyledTableHeaderContainer, StyledTopPage } from './CareerStyle';
import JobDetails from './JobDetails';
import JobList from './JobList';
import ModalForm from "./Modal";
import SearchBar from './SearchBar';

function jobSorter(a,b) {
  try {
    return parseInt(b.id.split("-")[1]) - parseInt(a.id.split("-")[1])
  } catch (err) {
    return b.id - a.id
  }
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Career() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let query = useQuery();
  let url = new URL(document.URL);
  let urlsp = url.searchParams;
  let urlspID = urlsp.get("id");
  const formAgreementOnly = urlsp.get("form-agreement-only") && urlsp.get("form-agreement-only") === "true";
  const [remoteFromSearch, setRemoteFromSearch] = useState("");

  //data from api(base.json)
  const [resultsData, setResultsData] = useState([]);
  const [currency, setCurrency] = useState(["EUR"])

  //Sort jobs
  const [sort, setSort] = useState("");

  //Job deatils state
  const [isVisible, setIsVisible] = useState(0);

  const [isVisibleMobile, setIsVisibleMobile] = useState(false);

  //SearchInputState
  const [searchInput, setSearchInput] = useState([]);
  const [searchInputSkills, setSearchInputSkills] = useState([]);
  const [searchInputLocation, setSearchInputLocation] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const isWide = useMedia({ minWidth: '1001px' });
  const isMobile = useMedia({ maxWidth: '701px' });


  const [actualCurrency, setActualCurrency] = useState(currency[0]);

  const handleCurrencyClick = (e) => {
    setActualCurrency(e.target.textContent);
  }

  //get data from api(base.json)
  useEffect(() => {
    if (!formAgreementOnly) {
      axios.get(`${process.env.REACT_APP_API_URL}/jobs`,
        { headers: { "Access-Control-Allow-Origin": "*" } }
      ).then((response) => {
        setIsLoading(false);
        setResultsData(response.data ? response.data.sort(jobSorter) : []);
        if (response.data && response.data.length > 0) {
          const allCurrencyArray = (response.data[0] && response.data[0].salary_rate)
            ? Object.keys(response.data[0].salary_rate)
            : ["EUR"];
          setCurrency(allCurrencyArray);
        }
      });
      const interval = setInterval(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/jobs`,
          { headers: { "Access-Control-Allow-Origin": "*" } }
        ).then((response) => {
          setIsLoading(false);
          setResultsData(response.data.sort(jobSorter));
          if (response.data.length > 0) {
            const allCurrencyArray = (response.data[0] && response.data[0].salary_rate)
              ? Object.keys(response.data[0].salary_rate)
              : ["EUR"];
            setCurrency(allCurrencyArray);
          }
        });
      }, 120000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleIsVisibleMobileClick = () => {
    setIsVisibleMobile(!isVisibleMobile);
    navigate("/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);
  }

  //Jobs details handle click
  const handleClick = (e) => {
    setIsVisibleMobile(!isVisibleMobile);
    if (e.target.id) {
      setIsVisible(e.target.id);
    } else {
      setIsVisible(e.target.parentNode.id);
    }
  }

  //Skills and location arrays to search input
  const optionsSkills = Object.values(Skills).map(skill => skill.name);
  const optionsJobsSkills = [...new Set(Object.values(resultsData).flatMap(job => Object.keys(job.skills)))]
  const optionsAllSkills = optionsSkills.concat(optionsJobsSkills);

  const optionsLocationWithoutDuplication = [];

  //Autosuggest array for search input
  const optionsWithoutDuplication = optionsAllSkills
    .sort((a, b) => a > b ? 1 : -1);
  const handleSearchInput = (event, newValue) => {
    setIsVisible(0);
    let skillsExist = optionsAllSkills.filter(x => newValue.includes(x));
    let remoteExist = newValue.some(v => v.toLowerCase().includes("remote"));

    if (optionsLocationWithoutDuplication.includes(newValue[newValue.length - 1])) {
    } else if (optionsAllSkills.includes(newValue[newValue.length - 1])) {
      setSearchInputSkills(skillsExist);
    };
    if (skillsExist.length === 0) {
      setSearchInputSkills([]);
    };
    if (remoteExist) {
      setRemoteFromSearch("remote");
    } else {
      setRemoteFromSearch("");
    };
    setSearchInput(newValue);
  };


  let results = [];
  if (searchInput && searchInput.length) {
    resultsData.filter((job) => {
      if (remoteFromSearch) {
        if (searchInput.length === 1) {
          if (job.location?.toLowerCase().includes(remoteFromSearch)) {
            results.push(job);
          }
        } else {
          if (job.location?.toLowerCase().includes(remoteFromSearch) &&
            (searchInput.some(val => job?.title?.toLowerCase().includes(val.toLowerCase())) ||
            searchInput.some(val => job?.description?.toLowerCase().includes(val.toLowerCase())))
          ) {
            results.push(job);
          }
        }
      }
      else if (
        searchInput.some(val => job?.title?.toLowerCase().includes(val.toLowerCase())) ||
        searchInput.some(val => job?.description?.toLowerCase().includes(val.toLowerCase()))
      ) {
        results.push(job);
      };
    });
  } else {
    results = [...resultsData];
  }
  if (results.length && !isVisible) {
    const jobByUrl = results.find(job => job.id === urlspID);
    if (jobByUrl) {
      setIsVisible(urlspID);
    } else {

    }
  }

  return (
    formAgreementOnly
    ? <ModalForm res={{id: "-1"}} open={formAgreementOnly} onClose={() => {navigate("/")}} />
    : <StyledPageBody >
        {isWide && <>
          <StyledTopPage >
            <SearchBar value={searchInput} options={optionsWithoutDuplication} onChange={handleSearchInput} />
          </StyledTopPage>

          {results.length > 0 && <StyledJobListContainer >
            <StyledJobList>
              <StyledTableHeaderContainer>
                <StyledJobListTitle>{t("career.jobs.detail.position")}</StyledJobListTitle>
                <StyledJobListContract>{t("career.jobs.detail.contract")}</StyledJobListContract>
                <StyledJobListRemote>{t("career.jobs.detail.length")}</StyledJobListRemote>
                <StyledJobListLocation>{t("career.jobs.detail.location")}</StyledJobListLocation>
              </StyledTableHeaderContainer>
              <JobList res={results} onClick={handleClick} isDisplay={isWide} mobile={isMobile} selectedItemId={isVisible} />
            </StyledJobList>
          </StyledJobListContainer>}

          <JobDetails jobId={query.get("id")} all={results} visible={isVisible} onClick={handleIsVisibleMobileClick} actual={actualCurrency} />
        </>}

        {!isWide && <>
          {(urlspID === null) && <StyledTopPage>
            <SearchBar value={searchInput} options={optionsWithoutDuplication} onChange={handleSearchInput} />
          </StyledTopPage>}

          {((urlspID === null) && results.length > 0) && <StyledJobListContainer >
            <StyledJobList>
              <StyledTableHeaderContainer>
                <StyledJobListTitle>{t("career.jobs.detail.position")}</StyledJobListTitle>
                <StyledJobListContract>{t("career.jobs.detail.contract")}</StyledJobListContract>
                <StyledJobListRemote>{t("career.jobs.detail.length")}</StyledJobListRemote>
                <StyledJobListLocation>{t("career.jobs.detail.location")}</StyledJobListLocation>
              </StyledTableHeaderContainer>
              <JobList res={results} onClick={handleClick} isDisplay={isWide} mobile={isMobile} actual={actualCurrency} />
            </StyledJobList>
          </StyledJobListContainer>}

          {(urlspID !== null) && <JobDetails jobId={query.get("id")} all={results} visible={isVisible} onClick={handleIsVisibleMobileClick} actual={actualCurrency} />}
        </>}

        {results.length === 0 && isLoading && <StyledNoResults >{t("career.jobs.detail.loading")}</StyledNoResults>}
        {results.length === 0 && !isLoading && <StyledNoResults >{t("career.jobs.detail.sorryNoResults")}</StyledNoResults>}

      </StyledPageBody>);
}

export default Career;
